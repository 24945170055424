import React from 'react';
import { Box, BoxProps } from '../Box';

export function Paragraph(props: BoxProps) {
    return (
        <Box
            as="p"
            lineHeight="base"
            fontSize="body"
            color="bodyText"
            {...props}
        />
    );
}
